import React, { Component } from 'react';
import _ from 'lodash';
import Card from './card';
import axios from 'axios';



export default class MainContainer extends Component {
  constructor() {
    super();
    this.state = {
      coins: [],
    }
    //this.url = 'https://thingproxy.freeboard.io/fetch/https://api.coinone.co.kr/ticker?currency=all'
    //this.url = 'https://demoapp.sandbox.acceleratelearning.com/currency'
    this.url = '/currency'
    this.headers = {
      'content-type': 'application/json',
      'accept': 'application/json',
    };

    this.options = {
      headers: this.headers,
      timeout: 5000,
      insecureHTTPParser: true,
    };
  }
  currencies = {};

  getData() {
    axios.get(this.url, this.options)
      .then(result => {

        let data = result.data;
        let coins = [];
        Object.keys(data).forEach((k, i) => {
          if (data[k].last) {
            coins.push(data[k]);
          }
        });
        this.setState({ coins: coins });
      });
  }
  timer() {
    this.getData();
  }
  componentDidMount() {
    this.intervalId = setInterval(this.timer.bind(this), 2000);
    this.getData();
  }
  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  renderCoinCard() {

    return _.map(this.state.coins, coin => {

      return (
        <Card key={coin.currency} coin={coin} />
      )
    });

  }
  render() {
    return (
      <div className="container">
        <div className="row">
          {this.state.coins.length > 0 && this.renderCoinCard()}
        </div>
      </div>
    );
  }
}
